


































































































import {
  DtoReportingForm,
  FormRules,
  reqArea,
  reqNewReporting,
  reqValidateCodeReportingPic,
  VoAreaList,
  smsSend2
} from "@/api";
import ReportMenu from "../components/ReportMenu.vue";
import { isEmail, isMobile } from "@/utils/regexps";
import { Form } from "element-ui";
import { CascaderProps } from "node_modules/element-ui/types/cascader-panel";
import { Component, Vue } from "vue-property-decorator";
import Reporting from "./Reporting.vue";

@Component({
  name: "real-name",
  components: {
    ReportMenu,
    Reporting
  }
})
export default class extends Vue {
  form: DtoReportingForm = {};
  disabled = false;
  count = 60;

  // todo [id:123]2024年个人信息保护专项治理行动举报专区 区别于其他通用收集
  uniqueCollect = false;

  created() {
    this.uniqueCollect = +this.$route.params.harmId === 123;
  }

  get cascaderProps(): CascaderProps<string, VoAreaList> {
    return {
      lazy: true,
      label: "name",
      value: "name",
      lazyLoad: async (node, resolve) => {
        const { data, level } = node;
        const { data: nodes } = await reqArea(level === 0 ? "0" : data.code);

        resolve(
          nodes.map(i => {
            return {
              ...i,
              leaf: level >= 2
            };
          })
        );
      }
    };
  }

  get form1Rules(): FormRules<DtoReportingForm> {
    const temp: FormRules<DtoReportingForm> = {
      trueName: [
        {
          required: true,
          message: `请输入真实姓名`,
          trigger: "blur"
        }
      ],
      sex: [
        {
          required: true,
          message: `请选择性别`,
          trigger: "blur"
        }
      ],
      validateCode0: [
        {
          required: true,
          message: `请输入图片验证码`,
          trigger: "blur"
        }
      ],
      phoneVerificationCode: [
        {
          required: true,
          message: `请输入短信验证码`,
          trigger: "blur"
        }
      ],
      email: [
        {
          required: true,
          message: `请输入邮箱`,
          trigger: "blur"
        },
        {
          validator: (rule, value, cb) => {
            if (isEmail(value)) {
              cb();
            }
            cb(new Error(`请输入正确的邮箱`));
          },
          trigger: "blur"
        }
      ],
      phoneNumber: [
        {
          required: true,
          message: `请输入手机号`,
          trigger: "blur"
        },
        {
          validator: (rule, val, cb) => {
            if (isMobile(val)) {
              cb();
            }
            cb(new Error(`请输入正确的手机号`));
          },
          trigger: "blur"
        }
      ]
    };
    if (this.uniqueCollect) {
      Reflect.deleteProperty(temp, "email");
    }
    return temp;
  }

  // 获取手机验证码
  getMsgCode() {
    const ref: any = this.$refs.form1;
    ref.validateField("validateCode0", (e: any) => {
      if (!e) {
        console.log(
          "You have entered information in the graphic verification code box"
        );
      } else {
        return false;
      }
    });

    ref.validateField("phoneNumber", (e: any) => {
      if (!e) {
        this.disabled = true;
        const params: any = {
          phoneNumber: this.form.phoneNumber || "",
          validateCode0: this.form.validateCode0
        };
        smsSend2(params.phoneNumber, params.validateCode0)
          .then(() => {
            this.$message.success("发送成功");
            this.countDown();
          })
          .catch(err => {
            // this.$message.error("系统错误");
            this.$message.error(err.response.data.message);
            this.picInitValid();
            this.disabled = false;
          });
      }
    });
  }
  // 倒计时
  countDown() {
    this.count--;
    if (!this.count) return (this.disabled = false), (this.count = 60);
    setTimeout(() => {
      this.countDown();
    }, 1000);
  }

  get areaChoose() {
    const { province, city, county } = this.form;
    return [province || "", city || "", county || ""];
  }

  set areaChoose(val: string[]) {
    this.form.province = val[0];
    this.form.city = val[1];
    this.form.county = val[2];
  }

  formValidHandler() {
    return (this.$refs.form1 as Form).validate();
  }

  picValidRequesting = false;
  picValidCode = "";

  get picValidImage() {
    return `data:image/png;base64,${this.picValidCode}`;
  }

  async picInitValid() {
    try {
      this.picValidRequesting = true;
      const { data } = await reqValidateCodeReportingPic();
      this.picValidCode = data;
    } finally {
      this.picValidRequesting = false;
    }
  }

  async initNewReporting() {
    const { data } = await reqNewReporting();
    this.form = data;
    this.form.reportingWay = "REAL_NAME_REPORT";
    await this.$nextTick();
    this.picInitValid();
    (this.$refs.reportor as Reporting).initValid();
  }

  listenForm() {
    (this.$refs.form1 as Form).resetFields();
  }

  mounted() {
    this.initNewReporting();
    (document as any)
      .getElementById("resetbutton")
      .addEventListener("click", () => {
        this.listenForm();
      });
  }
  beforeDestroy(): void {
    try {
      (document as any).getElementById("resetbutton").removeEventListener(
        "click",
        () => {
          this.listenForm();
        },
        false
      );
    } catch (err) {
      console.log(err);
    }
  }
}
